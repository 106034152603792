import { default as indexQ4JmP1yWziMeta } from "/usr/src/customer-portal-nuxt3.staging/pages/[businessCode]/[group]/[invoiceCode]/index.vue?macro=true";
import { default as index04QK0MHfH9Meta } from "/usr/src/customer-portal-nuxt3.staging/pages/[type]/[businessCode]/[group]/[invoiceCode]/index.vue?macro=true";
import { default as indexxXV0UkqWzGMeta } from "/usr/src/customer-portal-nuxt3.staging/pages/change/email/index.vue?macro=true";
import { default as indexKSrJQfTsSXMeta } from "/usr/src/customer-portal-nuxt3.staging/pages/change/password/index.vue?macro=true";
import { default as crezco_45loadinggqqgEfONwuMeta } from "/usr/src/customer-portal-nuxt3.staging/pages/crezco-loading.vue?macro=true";
import { default as indexP5S0ubvSK4Meta } from "/usr/src/customer-portal-nuxt3.staging/pages/index.vue?macro=true";
import { default as InvoiceseGmsoHpJhVMeta } from "/usr/src/customer-portal-nuxt3.staging/pages/Invoices.vue?macro=true";
import { default as indexRO7zV7nPLNMeta } from "/usr/src/customer-portal-nuxt3.staging/pages/login/index.vue?macro=true";
import { default as indexxxIH41fBE0Meta } from "/usr/src/customer-portal-nuxt3.staging/pages/validate/email/index.vue?macro=true";
import { default as indexSEL2hJ5Z93Meta } from "/usr/src/customer-portal-nuxt3.staging/pages/validate/password/index.vue?macro=true";
import { default as indexxFc2PzfhwgMeta } from "/usr/src/customer-portal-nuxt3.staging/pages/validate/phone/index.vue?macro=true";
import { default as indexARdHD7N1gmMeta } from "/usr/src/customer-portal-nuxt3.staging/pages/widget/[businessCode]/[group]/index.vue?macro=true";
import { default as indexS8tHT1QyBfMeta } from "/usr/src/customer-portal-nuxt3.staging/pages/widget/chat/[businessCode]/[group]/index.vue?macro=true";
import { default as index7IJ8jyNIKRMeta } from "/usr/src/customer-portal-nuxt3.staging/pages/widget/conversations/[businessCode]/[group]/index.vue?macro=true";
import { default as indexRkVFBM3zQpMeta } from "/usr/src/customer-portal-nuxt3.staging/pages/widget/documents/[businessCode]/[group]/index.vue?macro=true";
import { default as indexXN2ZyOYyeOMeta } from "/usr/src/customer-portal-nuxt3.staging/pages/widget/payments/[businessCode]/[group]/index.vue?macro=true";
export default [
  {
    name: "businessCode-group-invoiceCode",
    path: "/:businessCode()/:group()/:invoiceCode()",
    component: () => import("/usr/src/customer-portal-nuxt3.staging/pages/[businessCode]/[group]/[invoiceCode]/index.vue").then(m => m.default || m)
  },
  {
    name: "invoice-page",
    path: "/:type()/:businessCode()/:group()/:invoiceCode()",
    meta: index04QK0MHfH9Meta || {},
    component: () => import("/usr/src/customer-portal-nuxt3.staging/pages/[type]/[businessCode]/[group]/[invoiceCode]/index.vue").then(m => m.default || m)
  },
  {
    name: "change-email",
    path: "/change/email",
    component: () => import("/usr/src/customer-portal-nuxt3.staging/pages/change/email/index.vue").then(m => m.default || m)
  },
  {
    name: "change-password",
    path: "/change/password",
    component: () => import("/usr/src/customer-portal-nuxt3.staging/pages/change/password/index.vue").then(m => m.default || m)
  },
  {
    name: "crezco-loading",
    path: "/crezco-loading",
    component: () => import("/usr/src/customer-portal-nuxt3.staging/pages/crezco-loading.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/customer-portal-nuxt3.staging/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "Invoices",
    path: "/Invoices",
    component: () => import("/usr/src/customer-portal-nuxt3.staging/pages/Invoices.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/usr/src/customer-portal-nuxt3.staging/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "validate-email",
    path: "/validate/email",
    meta: indexxxIH41fBE0Meta || {},
    component: () => import("/usr/src/customer-portal-nuxt3.staging/pages/validate/email/index.vue").then(m => m.default || m)
  },
  {
    name: "validate-password",
    path: "/validate/password",
    meta: indexSEL2hJ5Z93Meta || {},
    component: () => import("/usr/src/customer-portal-nuxt3.staging/pages/validate/password/index.vue").then(m => m.default || m)
  },
  {
    name: "validate-phone",
    path: "/validate/phone",
    meta: indexxFc2PzfhwgMeta || {},
    component: () => import("/usr/src/customer-portal-nuxt3.staging/pages/validate/phone/index.vue").then(m => m.default || m)
  },
  {
    name: "widget-businessCode-group",
    path: "/widget/:businessCode()/:group()",
    meta: indexARdHD7N1gmMeta || {},
    component: () => import("/usr/src/customer-portal-nuxt3.staging/pages/widget/[businessCode]/[group]/index.vue").then(m => m.default || m)
  },
  {
    name: "widget-chat-businessCode-group",
    path: "/widget/chat/:businessCode()/:group()",
    meta: indexS8tHT1QyBfMeta || {},
    component: () => import("/usr/src/customer-portal-nuxt3.staging/pages/widget/chat/[businessCode]/[group]/index.vue").then(m => m.default || m)
  },
  {
    name: "widget-conversations-businessCode-group",
    path: "/widget/conversations/:businessCode()/:group()",
    meta: index7IJ8jyNIKRMeta || {},
    component: () => import("/usr/src/customer-portal-nuxt3.staging/pages/widget/conversations/[businessCode]/[group]/index.vue").then(m => m.default || m)
  },
  {
    name: "widget-documents-businessCode-group",
    path: "/widget/documents/:businessCode()/:group()",
    component: () => import("/usr/src/customer-portal-nuxt3.staging/pages/widget/documents/[businessCode]/[group]/index.vue").then(m => m.default || m)
  },
  {
    name: "widget-payments-businessCode-group",
    path: "/widget/payments/:businessCode()/:group()",
    meta: indexXN2ZyOYyeOMeta || {},
    component: () => import("/usr/src/customer-portal-nuxt3.staging/pages/widget/payments/[businessCode]/[group]/index.vue").then(m => m.default || m)
  }
]